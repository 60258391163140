<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-2"></div>
        <div class="col-lg-6 col-md-8 login-box">
          <div class="col-lg-12 login-key">
            <i class="bi bi-key-fill"></i>
          </div>
          <div class="col-lg-12 login-title">
            Birlik Dernek Giriş
          </div>
          <div class="col-lg-12 login-form">
            <div class="col-lg-12 login-form">
              <form autocomplete="off">
                <div class="form-group">
                  <label class="form-control-label">TC Kimlik Numarası</label>
                  <input type="text" class="form-control" autocomplete="off" v-model="tc">
                </div>
                <div class="form-group">
                  <label class="form-control-label">Şifre</label>
                  <input type="password" class="form-control" autocomplete="off" v-model="pw">
                </div>
                <div class="col-lg-12 loginbttm">
                  <div class="col-lg-6 login-btm login-text">
                    <span v-if="this.err">Hatalı giriş yapıldı. Lütfen bilgileri kontrol edin.</span>
                    <span v-else>&nbsp;</span>
                  </div>
                  <div class="col-lg-6 login-btm login-button">
                    <button type="button" class="btn btn-outline-primary" @click="login">Giriş Yap</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-3 col-md-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
export default {
  name: 'HomeView',
  data() {
    return {
      err: false,
      // tc: 1,
      // pw: '1'
      tc: null,
      pw: null
    }
  },
  mounted(){
    localStorage.removeItem('token');
  },
  methods: {
    login() {
      axios.get('https://birlikdernek.com/birseyler/api/login?tck=' + this.tc + '&sifre=' + this.pw)
          .then(r => {
            if (r.data.type == 'success'){
              localStorage.setItem('token', r.data.token)
              this.$router.push('/')
            }else{
              localStorage.removeItem('token')
              this.err = true
              setTimeout(() => {
                this.err = false
              }, 3000)
            }
          })
          .catch(e => {
            console.log(e)
            this.err = true
            setTimeout(() => {
              this.err = false
            }, 3000)
          })
    },
  }
}
</script>
<style scoped>

.login-box {
  margin-top: 75px;
  height: auto;
  background: #1A2226;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.login-key {
  height: 100px;
  font-size: 80px;
  line-height: 100px;
  background: -webkit-linear-gradient(#27EF9F, #0DB8DE);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-title {
  margin-top: 15px;
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
  margin-top: 15px;
  font-weight: bold;
  color: #ECF0F5;
}

.login-form {
  margin-top: 25px;
  text-align: left;
}

input[type=text] {
  background-color: #1A2226;
  border: none;
  border-bottom: 2px solid #0DB8DE;
  border-top: 0px;
  border-radius: 0px;
  font-weight: bold;
  outline: 0;
  margin-bottom: 20px;
  padding-left: 0px;
  color: #ECF0F5;
}

input[type=password] {
  background-color: #1A2226;
  border: none;
  border-bottom: 2px solid #0DB8DE;
  border-top: 0px;
  border-radius: 0px;
  font-weight: bold;
  outline: 0;
  padding-left: 0px;
  margin-bottom: 20px;
  color: #ECF0F5;
}

.form-group {
  margin-bottom: 40px;
  outline: 0px;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #0DB8DE;
  outline: 0;
  background-color: #1A2226;
  color: #ECF0F5;
}

input:focus {
  outline: none;
  box-shadow: 0 0 0;
}

label {
  margin-bottom: 0px;
}

.form-control-label {
  font-size: 10px;
  color: #6C6C6C;
  font-weight: bold;
  letter-spacing: 1px;
}

.btn-outline-primary {
  border-color: #0DB8DE;
  color: #0DB8DE;
  border-radius: 0px;
  font-weight: bold;
  letter-spacing: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.btn-outline-primary:hover {
  background-color: #0DB8DE;
  color: white;
  right: 0px;
}

.login-btm {
  float: left;
}

.login-button {
  padding-right: 0px;
  text-align: right;
  margin-bottom: 25px;
}

.login-text {
  text-align: left;
  padding-left: 0px;
  color: #A2A4A4;
}

.login-text span {
  color: #9f0000;
}

.loginbttm {
  padding: 0px;
}
</style>

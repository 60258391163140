import axios from 'axios';

const authGuard = (to, from, next) => {
    axios.get('https://birlikdernek.com/birseyler/api/me?token=' + localStorage.getItem('token'))
        .then(r => {
            if (r.data.token) {
                next()
            } else {
                next('/giris')
            }
        })
        .catch(e => {
            console.log(e)
        })
};

export default authGuard;

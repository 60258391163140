import { createRouter, createWebHistory } from 'vue-router'
import login from '../views/Login.vue'
import authGuard from "@/router/authGuard";

const routes = [
  {
    path: '/giris',
    name: 'giris',
    component: login
  },
  {
    path: '/yonet',
    name: 'redirect-giris',
    component: () => import(/* webpackChunkName: "about" */ '../views/yonet.vue')
  },
  {
    path: '/',
    name: 'redirect-dekontYukle',
    meta: { auth: true },
    redirect: '/dekontYukle',
  },
  {
    path: '/dekontYukle',
    name: 'dekontYukle',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/dekontYukle.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/duyurular',
    name: 'duyuruLar',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/duyurular.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/odemeListesi',
    name: 'odemeListesi',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/odemeListesi.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/gecmisOdemeler',
    name: 'gecmisOdemeler',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/gecmisOdemeler.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/envanter',
    name: 'envanTer',
    meta: { auth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/envanter.vue'),
    beforeEnter: authGuard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
